import Maintenance from 'components/common/maintenance';
import DashboardContainer from 'components/containers/dashboard.container';
import NotfoundError from 'components/errors/notfound.error';
import SuperLinkForm from 'components/super-links/super-link.form';
import SuperLinks from 'components/super-links/super-links';
import ManageUsers from 'pages/admin/manage-users/manage-users';
import ManageUsersForm from 'pages/admin/manage-users/manage-users.form';
import Amazon from 'pages/users/amazon/amazon';
import AmazonLoginRedirect from 'pages/users/amazon/amazon-login-redirect';
import AmazonTokenRedirect from 'pages/users/amazon/amazon-token-redirect';
import Analytics from 'pages/users/analytics/analytics';
import ConfirmEmailDone from 'pages/users/confirm-email/confirm-email.done';
import ConfirmEmailFail from 'pages/users/confirm-email/confirm-email.fail';
import FunnelCustomers from 'pages/users/funnels/funnel-customers/funnel-customers';
import FunnelForm from 'pages/users/funnels/funnel.form';
import Funnels from 'pages/users/funnels/funnels';
import RebateFunnelRebateMain from 'pages/users/funnels/rebate/tabs/rebate-funnel-rebate.main';
import RebateFunnelLabels from 'pages/users/funnels/rebate/tabs/rebate-funnel.labels';
import RebateFunnelRebateOptions from 'pages/users/funnels/rebate/tabs/rebate-funnel.options';
import RebateFunnelSite from 'pages/users/funnels/rebate/tabs/rebate-funnel.site';
import RebateFunnelBasicInfo from 'pages/users/funnels/rebate/tabs/rebates-basic-info';
import RebateFunnelBasicInfoEdit from 'pages/users/funnels/rebate/tabs/rebates-basic-info-edit';
import HeatMaps from 'pages/users/heatmap/heat-maps';
import HeatmapUpload from 'pages/users/heatmap/images/heatmap-upload';
import HeatmapUploads from 'pages/users/heatmap/images/heatmap-uploads';
import PPCAI from 'pages/users/ppc-ai/ppc-ai';
import ProductInfo from 'pages/users/products/product.info';
import Products from 'pages/users/products/products';
import MagicLink from 'pages/users/magic-links/magic-link';
import AddMagicLink from 'pages/users/magic-links/magic-link-form.component';
import ResetPassword from 'pages/users/reset-password/reset-password';
import ResetPasswordFail from 'pages/users/reset-password/reset-password-fail';
import ResetPasswordDone from 'pages/users/reset-password/reset-password.done';
import ChangePassword from 'pages/users/settings/change-password.form';
import UserSettings from 'pages/users/settings/user-settings';
import Signup from 'pages/users/signup/signup';
import SignupPayment from 'pages/users/signup/signup-payment';
import SignupSuccess from 'pages/users/signup/signup-success';
import SplitTestInput from 'pages/users/split-tests/split-test-input';
import SplitTestResult from 'pages/users/split-tests/split-test-result';
import SplitTestUploadResult from 'pages/users/split-tests/split-test-upload-result';
import SplitTests from 'pages/users/split-tests/split-tests';
import SplitTestsForm from 'pages/users/split-tests/split-tests.form';
import PaymentMethodStatus from 'pages/users/subscription/payment-method/payment-method-status';
import Reactivate from 'pages/users/subscription/reactivate';
import Pricing from 'pages/users/subscription/subscription';
import InviteMemberForm from 'pages/users/teams/invite-member.form';
import Teams from 'pages/users/teams/teams';
import Forgot from 'pages/users/user/forgot';
import Login from 'pages/users/user/login';
import { Route, Routes } from 'react-router-dom';
import KeywordExtractor from 'pages/users/apollo/keyword-extractor/keyword-extractor';
import Apollo from 'pages/users/apollo/apollo';
import ApolloCTA from 'pages/users/apollo/apollo.cta';
import ApolloListing from 'pages/users/apollo/apollo.listing';
import { ApolloCreateListingTabs } from 'pages/users/apollo/steps/apollo.create-listing-tabs';
import { ABTestUpload } from 'pages/users/ab-test/abtest.upload';
import { ABTestGoal } from 'pages/users/ab-test/abtest.goal';
import { ABTestUploadOutput } from 'pages/users/ab-test/abtest.upload-output';
import Abtest from './pages/users/ab-test/abtest';
import ABTestResults from './pages/users/ab-test/abtest.results';
import { ApolloPrompts } from 'pages/users/apollo/apollo.prompts';
import ManageUsersEdit from 'pages/admin/manage-users/manage-users.edit';
import { Prompts } from 'pages/admin/prompts/prompt';
import TopSearches from './pages/users/top-searches/top-searches';
import TopSearchesTopAsins from './pages/users/top-searches/top-searches.top-asins';
import ABTestAutoLogin from './pages/users/ab-test/abtest.auto-login';
import React from 'react';
import HeatMapsUpload from 'pages/users/heat-maps/images/heat-maps-upload';
import NewHeatMaps from 'pages/users/heat-maps/heat-maps';
import HeatMapsUploads from 'pages/users/heat-maps/images/heat-maps-uploads';
import KeywordResearch from 'pages/users/keyword-research/keyword-research';
import AbtestDefine from 'pages/users/ab-test/abtest-define-elements/abtest-define';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup/:priceId/:type?" element={<Signup />} />
      <Route path="/signup-payment/:priceId/:userId/:email/:type" element={<SignupPayment />} />
      <Route path="/signup-success" element={<SignupSuccess />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/reset-password/:email/:passwordResetToken" element={<ResetPassword />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route path="/reset-password/invalid-token" element={<ResetPasswordFail />} />
      <Route path="/reset-password/done" element={<ResetPasswordDone />} />
      <Route path="/confirm-email/done" element={<ConfirmEmailDone />} />
      <Route path="/confirm-email/fail" element={<ConfirmEmailFail />} />

      <Route path="dashboard" element={<DashboardContainer />}>
        <Route index element={<Analytics />} />
        <Route path="funnels">
          <Route index element={<Funnels />} />
          <Route path=":id" element={<FunnelForm />} />
          <Route path=":id/funnel-customers" element={<FunnelCustomers />} />
          <Route path="rebates/info/new" element={<RebateFunnelBasicInfo />} />
          <Route path="rebates/info/:id" element={<RebateFunnelBasicInfoEdit />} />
          <Route path="rebates/main/:id" element={<RebateFunnelRebateMain />} />
          <Route path="rebates/options/:id" element={<RebateFunnelRebateOptions />} />
          <Route path="rebates/site/:id" element={<RebateFunnelSite />} />
          <Route path="rebates/labels/:id" element={<RebateFunnelLabels />} />
          <Route path="rebates/labels/:label/:id" element={<RebateFunnelLabels />} />
        </Route>
        <Route path="magic-urls">
          <Route index element={<MagicLink />} />
          <Route path="new" element={<AddMagicLink />} />
        </Route>
        <Route path="heatmaps-old">
          <Route index element={<HeatMaps />} />
          <Route path=":id/uploads/new" element={<HeatmapUpload />} />
          <Route path=":id/uploads" element={<HeatmapUploads />} />
        </Route>
        <Route path="heatmaps">
          <Route index element={<NewHeatMaps />} />
          <Route path=":id/uploads/new" element={<HeatMapsUpload />} />
          <Route path=":id/uploads" element={<HeatMapsUploads />} />
        </Route>
        {/*<Route path="brands">*/}
        {/*  <Route index element={<Brands />} />*/}
        {/*  <Route path=":id" element={<BrandsForm />} />*/}
        {/*</Route>*/}
        {/*<Route path="seller-accounts">*/}
        {/*  <Route index element={<SellerAccounts />} />*/}
        {/*  <Route path=":id" element={<SellerAccountForm />} />*/}
        {/*</Route>*/}
        {/*<Route path="accounts">*/}
        {/*  <Route index element={<Accounts />} />*/}
        {/*  <Route path=":id" element={<AccountsForm />} />*/}
        {/*</Route>*/}
        <Route path="split-tests">
          <Route index element={<SplitTests />} />
          <Route path="new" element={<SplitTestsForm />} />
          <Route path="upload" element={<SplitTestsForm />} />
          <Route path="upload-result/:id" element={<SplitTestUploadResult />} />
          <Route path="result/:id" element={<SplitTestResult />} />
          <Route path="input/:id" element={<SplitTestInput />} />
        </Route>
        <Route path="amazon" element={<Amazon />} />
        <Route path="brand-analytics/tsq" element={<TopSearches />} />
        <Route path="brand-analytics/tsq-top-asins" element={<TopSearchesTopAsins />} />
        <Route path="brand-analytics/keyword-research" element={<KeywordResearch />} />
        <Route path="settings">
          <Route index element={<UserSettings />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="users">
          <Route index element={<ManageUsers />} />
          <Route element={<ManageUsers />} path=":status" />
          <Route element={<ManageUsersEdit />} path="manage/:id" />
          <Route path="edit/:id" element={<ManageUsersForm />} />
        </Route>
        <Route path="prompts">
          <Route index element={<Prompts />} />
          <Route path=":type/:tabValue" element={<Prompts />} />
        </Route>
        <Route path="abtests">
          <Route index element={<Abtest />} />
          <Route path="upload" element={<ABTestUpload />} />
          <Route path="upload/:id" element={<ABTestUpload />} />
          <Route path="goal/:id" element={<ABTestGoal />} />
          <Route path="upload/output/:id" element={<ABTestUploadOutput />} />
          <Route path="results/:id" element={<ABTestResults />} />
          <Route path="define/:id" element={<AbtestDefine />} />
        </Route>

        <Route path="products">
          <Route index element={<Products />} />
          <Route path=":id" element={<ProductInfo />} />
        </Route>
        <Route path="superlinks">
          <Route index element={<SuperLinks />} />
          <Route path=":id" element={<SuperLinkForm />} />
        </Route>
        <Route path="subscription">
          <Route index element={<Pricing />} />
          <Route path="payment-method" element={<PaymentMethodStatus />} />
          <Route path="reactivate/:priceId" element={<Reactivate />} />
        </Route>
        <Route path="teams">
          <Route index element={<Teams />} />
          <Route path=":id" element={<InviteMemberForm />} />
        </Route>
        <Route path="keyword-extractor">
          <Route index element={<KeywordExtractor hasNext={false} />} />
        </Route>
        <Route path="apollo">
          <Route index element={<Apollo />} />
          <Route path="start" element={<ApolloCTA />} />
          <Route path=":id" element={<ApolloCreateListingTabs component="title" />} />
          <Route path="keyword-extractor" element={<KeywordExtractor hasNext={true} />} />
          <Route path="prompt/:id" element={<ApolloPrompts />} />
          <Route path="create-list">
            <Route element={<ApolloCreateListingTabs />} path=":tabValue/:id" />
            <Route element={<ApolloListing />} path="brand" />
            {/*<Route element={<ApolloListing />} path="brand" />*/}
          </Route>
        </Route>
      </Route>
      <Route path="/login-redirect/:type" element={<AmazonLoginRedirect />} />
      <Route path="/token-redirect/:type" element={<AmazonTokenRedirect />} />
      <Route path="/upload-redirect/:id" element={<ABTestAutoLogin />} />

      <Route path="/ppc-ai" element={<PPCAI />} />
      <Route path="*" element={<NotfoundError />} />
    </Routes>
  );
}
